import React from "react"
import HomeHeroSection from '@loggedOut/HomeHeroSection'
import { CTAWave, StorySlider, TextCoverRow } from '@components/common'
import HeroMenu  from '@menus/HeroMenu'
import { useTranslation } from 'react-i18next'

const LoggedOutcategory = ({stories, category, description, sliderContent}) => {
  const { t } = useTranslation()


  return (
    <>
      <HeroMenu/>
      <HomeHeroSection 
        headerTitle={category} 
        headerText={t('logged-out-category.headerText', { category: category })} 
        ctaText={t('logged-out-category.cta-1')}/>
      {description && 
        <TextCoverRow
          text={description}
          story={stories[0].node}
        />
      }
      <StorySlider
        title={t('logged-out-category.sliderTitle-1', { category: category })} 
        subtitle = {t('logged-out-category.sliderSubtitle-1')}
        stories={stories}
        contentIds={sliderContent}
      />
      <CTAWave 
        title ={t('logged-out-category.cta-title')}
        buttonText={t('logged-out-category.cta-2')}
      />
   </>
  );
}

export default LoggedOutcategory