import React from "react";
import styled from 'styled-components'
import {ListViewItem, AppPageHeader} from '@common';
import { usePageContext }  from '@hooks/usePageContext'
import { useTranslation } from 'react-i18next'

const CategoryWrapper = styled.div`
  padding: 0 5% 5% 5%;
  background-color: ${props => props.theme.backgroundColor};
  min-height:100vh;
`
const LoggedInCategory = ({fantasy, stories}) => {
  const {lang} = usePageContext()
  const { t } = useTranslation()

  return (
    <>
      <AppPageHeader pageTitle={`${t('logged-in-category.title')}: ${fantasy}`}/>
      <CategoryWrapper>
        {stories && stories.map(edge =>(
          <ListViewItem
            id={edge.node.id}
            slug={edge.node[lang].slug}
            title={edge.node[lang].title}
            premiumAudioRef={edge.node[lang].premiumAudioRef}
            sampleAudioRef={edge.node[lang].sampleAudioRef}
            freeAudioRef={edge.node[lang].freeAudioRef}
            storyCover={edge.node.localImage.childImageSharp.gatsbyImageData}
            thumbnail={edge.node.localImage.smallImage.gatsbyImageData}
            duration={edge.node[lang].duration}
            voices={edge.node[lang].voices}
            mainFantasy={edge.node.mainFantasy[lang].fantasy}
            spiciness={edge.node.spiciness}
            premiumContent={edge.node[lang].premiumContent}
            audiodesiresOriginal={edge.node.audiodesiresOriginal}
            key={edge.node.id}
          />
        ))} 
      </CategoryWrapper >
    </>
  )
}

export default LoggedInCategory