import React, {useContext} from "react";
import {graphql} from 'gatsby';
import { useTranslation } from 'react-i18next'
import {useFilterStoriesByFantasy} from '@hooks/useFilterStoriesByFantasy'
import {useObjectifyStoryArray} from '@hooks/useObjectifyStoryArray'
import {FirebaseContext} from '@components/Firebase';
import Seo from "@components/Seo";
import FantasyPage from '@common/loggedIn/FantasyPage'
import LoggedOutFantasy from '@loggedOut/LoggedOutFantasy';
import Header from "@common/menus/header"

const StoryFantasyTemplate = (props) => {
  const storiesObject = useObjectifyStoryArray(props.data.allStory)
  const { t } = useTranslation()
  const {user} = useContext(FirebaseContext);
  const seoTitle =  t('story-fantasy-template.seoTitle', { fantasy: props.pageContext.fantasy })
  const seoDescription = t('story-fantasy-template.seoDescription', { fantasy: props.pageContext.fantasy })
  const customQuery  = useFilterStoriesByFantasy(props.data.allStory.edges, props.pageContext.fantasySlug, props.pageContext.lang)

  return(
    <>
      <Header location={props.location}/> 
      <Seo description={seoDescription} title={seoTitle}/>
      {!!user ? 
        <FantasyPage stories={customQuery} fantasy={props.pageContext.fantasy} />
      :
        <LoggedOutFantasy 
          stories={storiesObject} 
          category={props.pageContext.fantasy} 
          description={props.pageContext.description} 
          sliderContent={props.pageContext.contentIds}
        />
      }
    </>
  )
 };


// get proper filter query working
export const query = graphql`
query FantasyTemplateQuery ($isEn: Boolean!, $isEs: Boolean!, $isDe: Boolean!)
  {
    allStory{
      edges {
        node {
          ...allStoryInfo
        }
      }
    }
    allFantasy {
      edges {
        node {
          id
          en @include(if: $isEn){
            fantasy
          }
          es @include(if: $isEs){
            fantasy
          }
          de @include(if: $isDe){
            fantasy
          }
        }
      }
    }
  }
` 


export default StoryFantasyTemplate;